import { enumerateDevices } from 'features/user-media/utils';

export const mapSDKLabelsToDeviceIds = async (
  requestedDevices: Partial<Record<MediaDeviceKind, string>>
) => {
  const enumeratedMediaDevices = await enumerateDevices();
  Object.entries(requestedDevices).forEach(([kind, label]) => {
    const device = enumeratedMediaDevices[kind as MediaDeviceKind].find(
      (lookupDevice) => lookupDevice.label === label
    );

    if (device) {
      try {
        localStorage.setItem(kind, device.deviceId);
      } catch {
        // mute error
      }
    }
  });
};
