import { streamMaximized } from 'features/layout/actions';
import { selectCurrentLayoutMode } from 'features/layout/features/config/configSlice';
import {
  selectMaximizedStream,
  selectPreviouslyMaximizedStream,
} from 'features/layout/features/content/contentSlice';
import { selectActiveSpeaker } from 'features/layout/features/order/orderSlice';
import { selectFilteredStreams } from 'features/layout/selectors';
import { LayoutMode, SourceDetails } from 'features/layout/types';
import { sourceMatches } from 'features/streaming/utils';
import { put, select } from 'redux-saga/effects';

export function* findContentForStageSaga(
  includePastScreenshare?: boolean,
  deletedStream?: SourceDetails
) {
  const activeMode: LayoutMode = yield select(selectCurrentLayoutMode);

  const currentlyMaximizedStream: SourceDetails = yield select(selectMaximizedStream);

  const screenshareOnStage = ['screenshare-remote', 'screenshare-local'].includes(
    currentlyMaximizedStream?.kind
  );

  if (
    (screenshareOnStage && !sourceMatches(currentlyMaximizedStream, deletedStream)) ||
    activeMode === LayoutMode.tiled
  ) {
    // no need to update UI;
    return;
  }

  // by default maximize active speaker
  let maximizedStream: SourceDetails | null = yield select(selectActiveSpeaker);
  let orderedStreams: SourceDetails[] = yield select(selectFilteredStreams);

  // remove active speakers tile in auto mode if it shouldnt be visible because of tile visibility flag
  if (!orderedStreams.find((source) => sourceMatches(source, maximizedStream))) {
    maximizedStream = null;
  }

  // @FIXME: An ugly block but it helps cover a bunch of edge
  //  cases because this saga is called from so many places;
  // don't know what's the better solution since we have all these "special" conditions for screenshare
  if (!includePastScreenshare) {
    const excludedStream: SourceDetails = currentlyMaximizedStream
      ? null
      : yield select(selectPreviouslyMaximizedStream);

    orderedStreams = orderedStreams.filter((source) =>
      excludedStream?.kind === 'screenshare-remote' ? !sourceMatches(source, excludedStream) : true
    );
  }

  const screenshareStream = orderedStreams.find((stream) =>
    ['screenshare-remote', 'screenshare-local'].includes(stream.kind)
  );

  if (screenshareStream) {
    maximizedStream = screenshareStream;
  } else if (!maximizedStream) {
    [maximizedStream] = orderedStreams;
  }

  if (maximizedStream) {
    yield put(streamMaximized({ source: maximizedStream }));
  }
}
