import {
  ContentLibraryFile,
  ContentLibraryFiles,
  ContentLibraryFolder,
  ContentLibraryFolders,
  ContentLibraryReceivedPayload,
  FileReceivedPayload,
  FolderReceivedPayload,
} from 'features/content-library/types';

interface NormalizedData {
  folders: ContentLibraryFolders;
  files: ContentLibraryFiles;
}

export const normalizeFolder = (folder: FolderReceivedPayload): ContentLibraryFolder => ({
  id: folder.id,
  name: folder.name,
  parentId: folder.parentId || 'root',
  date: folder.date,
  childFolderIds: [],
  childFileIds: [],
  childUploadIds: [],
});

export const normalizeFile = (file: FileReceivedPayload): ContentLibraryFile => ({
  id: file.id,
  name: file.name,
  type: file.type,
  folderId: file.folderId || 'root',
  url: file.url,
  thumbnailUrl: file.thumbnailUrl,
});

export const normalizeContentLibrary = (data: ContentLibraryReceivedPayload) => {
  const normalizedData: NormalizedData = {
    folders: {
      root: {
        id: 'root',
        name: 'Root Directory',
        parentId: '',
        childFolderIds: [],
        childFileIds: [],
        childUploadIds: [],
        date: null,
      },
    },
    files: {},
  };

  for (const folder of data.folders) {
    const normalizedFolder = normalizeFolder(folder);
    normalizedData.folders[normalizedFolder.id] = normalizedFolder;

    if (normalizedData.folders[normalizedFolder.parentId]) {
      // connect to parent folder
      normalizedData.folders[normalizedFolder.parentId].childFolderIds.push(normalizedFolder.id);
    }
  }

  for (const file of data.files) {
    const normalizedFile = normalizeFile(file);
    const parentFolderId = normalizedFile.folderId;

    normalizedData.files[normalizedFile.id] = normalizedFile;

    if (normalizedData.folders[parentFolderId]) {
      // connect to parent folder
      normalizedData.folders[parentFolderId].childFileIds.push(normalizedFile.id);
    }
  }

  return normalizedData;
};
