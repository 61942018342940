import { signalingConnected } from 'features/application/actions';
import { RoomLoginFormValues } from 'features/room/components/RoomLogin';
import { loginFailed, loginFinished, loginStarted, selectRoomToken } from 'features/room/roomSlice';
import { prepareJoinScreen } from 'features/room/sagas/prepareJoinScreen';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { PrejoinResponse, RoomToken } from 'features/room/types';
import { onConfigureVBCommandSaga } from 'features/sdk/onConfigureVBCommandSaga';
import { onControlEventListenersCommandSaga } from 'features/sdk/onControlEventListenersCommandSaga';
import { onControlTileActionSaga } from 'features/sdk/onControlTileActionSaga';
import { onControlUICallbackSaga } from 'features/sdk/onControlUICallbackSaga';
import { SDKConnected } from 'features/sdk/sdkStateSlice';
import { validateSDKInitStateSaga } from 'features/sdk/validateSDKInitStateSaga';
import { call, put, select, take } from 'redux-saga/effects';
import { RootState, SagaThunkAction, SagaWrappedThunkAction } from 'store/store';
import { eventBus, SDKConnectionPayload } from 'utils/eventBus';
import { mapSDKLabelsToDeviceIds } from 'utils/sdk/mapSDKLabelsToDeviceIds';
import { AudioPool } from 'utils/webrtc/AudioPool';

export function* onSDKConnectedSaga(rawInitState: SDKConnectionPayload) {
  const initState: SDKConnectionPayload = yield validateSDKInitStateSaga(rawInitState);

  yield call(eventBus.onConnection, initState);

  yield put(SDKConnected(initState || {}));

  if (initState.mediaDevices) {
    yield call(mapSDKLabelsToDeviceIds, initState.mediaDevices);
  }

  const token: RoomToken = yield select(selectRoomToken);

  if (!token?.parsedData?.u) {
    if (initState.username) {
      yield put(loginStarted());

      try {
        const action: SagaThunkAction<
          PrejoinResponse,
          string | undefined,
          {
            state: RootState;
            rejectValue: RoomLoginFormValues;
          }
        > = yield call(authorizeUser, initState.username);

        const resultedAction: SagaWrappedThunkAction<
          PrejoinResponse,
          string | undefined,
          {
            state: RootState;
            rejectValue: RoomLoginFormValues;
          }
        > = yield put(action);

        yield call(resultedAction.unwrap);
        yield take(signalingConnected);
        yield put(loginFinished());
      } catch (e: any) {
        yield put(loginFailed(e.login));
      }
    }

    yield call(prepareJoinScreen);
  }

  if (initState.muteFrame) {
    yield call(AudioPool.muteAll);
  } else {
    yield call(AudioPool.unmuteAll);
  }

  if (initState.virtualBackground) {
    yield call(onConfigureVBCommandSaga, initState.virtualBackground, false);
  }

  if (initState.eventListeners?.length) {
    for (const event of initState.eventListeners) {
      yield call(onControlEventListenersCommandSaga, event.operation, {
        eventName: event.event,
        target: event.target,
      });
    }
  }

  if (initState.UICallbacks?.length) {
    for (const callback of initState.UICallbacks) {
      yield call(onControlUICallbackSaga, callback.operation, {
        name: callback.name,
      });
    }
  }

  if (initState.tileActions?.length) {
    for (const action of initState.tileActions) {
      const data = {
        name: action.name,
        properties: action.properties,
      };

      yield call(onControlTileActionSaga, action.operation, data);
    }
  }
}
