import { consentConfigReceived } from 'features/room/roomSlice';
import { SDKInitialized } from 'features/sdk/sdkStateSlice';
import i18n from 'i18n';
import { call, fork, put, select, take } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';
import { AudioPool } from 'utils/webrtc/AudioPool';
import { logEnvSaga } from 'features/application/sagas/logEnvSaga';
import { EnumeratedMediaDevices } from 'features/user-media/types';
import { enumerateDevices } from 'features/user-media/utils';
import { mediaDevicesUpdated } from 'features/user-media/userMediaSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { InitRoomPayload, PrejoinResponse, ValidateRoomResult } from 'features/room/types';
import { validateRoomSaga } from 'features/room/sagas/validateRoomSaga';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { RootState, SagaThunkAction, SagaWrappedThunkAction } from 'store/store';
import { RoomLoginFormValues } from 'features/room/components/RoomLogin';
import { signalingConnected } from 'features/application/actions';
import { brandingReceived } from 'features/branding/brandingSlice';
import { initSentrySaga } from 'features/application/sagas/initSentrySaga';
import { prepareJoinScreen } from 'features/room/sagas/prepareJoinScreen';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { roomErrorSaga } from 'features/room/sagas/roomErrorSaga';
import { enableE2eeSaga } from 'features/e2ee/sagas/enableE2eeSaga';
import { ContentLibraryManager } from 'utils/content-library/ContentLibraryManager';

export function* initRoomSaga({ payload: { roomId } }: PayloadAction<InitRoomPayload>) {
  try {
    const { token, settings }: ValidateRoomResult = yield call(validateRoomSaga, roomId);

    if (settings.sentryEnabled) {
      yield fork(initSentrySaga);
    }

    if (settings.e2EeEnabled) {
      yield call(enableE2eeSaga);
    }

    yield put(SDKInitialized(eventBus.isEmbed));

    yield fork(logEnvSaga);
    yield call(AudioPool.initialize);

    yield fork(ContentLibraryManager.initialize);

    yield put(brandingReceived(settings.branding));

    if (settings.consent) {
      if (settings.consent.type === 'generic') {
        settings.consent.message = i18n.t('join:gdpr.message');
        settings.consent.checkboxMessage = i18n.t('join:gdpr.checkbox_message');
      }

      yield put(consentConfigReceived(settings.consent));
    }

    const isRecorderSession: boolean = yield select(selectIsRecorderSession);
    if (!isRecorderSession) {
      const mediaDevices: EnumeratedMediaDevices = yield call(enumerateDevices);
      yield put(mediaDevicesUpdated(mediaDevices));
    }

    if (token.parsedData?.u) {
      // no need to show the login form, authorize a user straight away
      const action: SagaThunkAction<
        PrejoinResponse,
        string | undefined,
        {
          state: RootState;
          rejectValue: RoomLoginFormValues;
        }
      > = yield call(authorizeUser);

      const resultedAction: SagaWrappedThunkAction<
        PrejoinResponse,
        string | undefined,
        {
          state: RootState;
          rejectValue: RoomLoginFormValues;
        }
      > = yield put(action);

      yield call(resultedAction.unwrap);
      yield take(signalingConnected);
    }

    yield call(prepareJoinScreen);
  } catch (error) {
    yield call(roomErrorSaga, error);
  }
}
