import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import CameraControl from 'features/toolbar/Toolbar/controls/CameraControl';
import { useEndSessionControl } from 'features/toolbar/Toolbar/controls/end-call/useEndSessionControl';
import MicControl from 'features/toolbar/Toolbar/controls/MicControl';
import RaiseHandControl from 'features/toolbar/Toolbar/controls/raise-hand/RaiseHandControl';
import { useRaiseHandControl } from 'features/toolbar/Toolbar/controls/raise-hand/useRaiseHandControl';
import { MenuFixedItems, Root, ToolbarBg, ToolbarNavRoot } from 'features/toolbar/Toolbar/styles';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export const PipToolbar = () => {
  const { hasPermissions } = usePermissions();
  const { icon, leaveSession } = useEndSessionControl();
  const allowBroadcast = hasPermissions(PermissionTypes.broadcast);
  const { isFeatureAvailable: raiseHandAvailable } = useRaiseHandControl();

  const { t } = useTranslation('room');

  const primaryMenuItems = [];

  if (allowBroadcast) {
    primaryMenuItems.push(
      <Fragment key="broadcast-controls">
        <CameraControl />
        <MicControl />
      </Fragment>
    );
  }

  if (raiseHandAvailable) {
    primaryMenuItems.push(<RaiseHandControl />);
  }

  primaryMenuItems.push(
    <ToolbarMenuItem
      overlay={t('toolbar.leave_session')}
      ariaLabel={t('toolbar.leave_session')}
      icon={icon}
      id="end-call"
      onClick={leaveSession}
    />
  );

  return (
    <Root placement="bottom" orientation="horizontal">
      <ToolbarBg />
      <ToolbarNavRoot orientation="horizontal" style={{ position: 'relative', zIndex: 2 }}>
        <MenuFixedItems orientation="horizontal" style={{ justifyContent: 'center' }}>
          {primaryMenuItems}
        </MenuFixedItems>
      </ToolbarNavRoot>
    </Root>
  );
};
