import { GDPRConfig, RoomToken } from 'features/room/types';
import { selectIsSDKConnected, selectSDKInitState } from 'features/sdk/sdkStateSlice';
import { put, select } from 'redux-saga/effects';
import {
  roomStatusUpdated,
  selectConsentConfig,
  selectLoginError,
  selectLoginSubmitted,
  selectRoomToken,
} from 'features/room/roomSlice';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { roomJoinRequest } from 'features/room/actions';
import { getConsentFlag } from 'utils/consentFlag';
import { SDKInitState } from 'utils/eventBus';

export function* prepareJoinScreen() {
  const sdkConnected: boolean = yield select(selectIsSDKConnected);
  // if we're in an SDK session, it might not be initialized fully upon signaling login;
  // in that case, we display loader until SDK is configured and then run this saga once againg
  // setting the correct state;

  if (!sdkConnected) {
    return;
  }

  const isRecorderSession: boolean = yield select(selectIsRecorderSession);
  if (isRecorderSession) {
    yield put(roomJoinRequest());
    return;
  }

  const sdkInitState: SDKInitState = yield select(selectSDKInitState);

  const token: RoomToken = yield select(selectRoomToken);

  const loginSubmitted: boolean = yield select(selectLoginSubmitted);
  const loginError: string | undefined = yield select(selectLoginError);

  const loginRequired = loginSubmitted ? loginError : !token.parsedData?.u;

  const requireSDKLogin = sdkInitState.username && !token.parsedData?.u;

  if (requireSDKLogin && !(loginSubmitted || loginError)) {
    return;
  }

  const consentConfig: GDPRConfig | null = yield select(selectConsentConfig);

  const consentFlag = getConsentFlag();

  const consentRequired = consentConfig && !consentFlag;

  if (loginRequired) {
    yield put(roomStatusUpdated('login'));
    return;
  }

  if (consentRequired) {
    yield put(roomStatusUpdated('consent-screen'));
    return;
  }

  yield put(roomStatusUpdated('join'));
}
