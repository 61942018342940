// Browsers reveal a valid device list only after granting permissions
import { UpdateMediaDevicesFunction } from 'hooks/useMediaDevices';
import { getUserMedia } from 'features/user-media/utils/getUserMedia';
import webrtcAdapter from 'webrtc-adapter';
import {
  enumerateDevices,
  getBaseMediaConstraints,
  getDevicePermissions,
  stopStreamTracks,
} from 'features/user-media/utils/index';

export const refineMediaDevices = async (updateMediaDevices: UpdateMediaDevicesFunction) => {
  const requestUserMedia = async () => {
    const updatedMediaDevices = await enumerateDevices();
    const constraints: MediaStreamConstraints = getBaseMediaConstraints(updatedMediaDevices);

    const stream = await getUserMedia(constraints);
    const mediaDevices = await updateMediaDevices({
      updatePermissions: true,
    });
    stopStreamTracks(stream);

    return mediaDevices;
  };

  if (webrtcAdapter.browserDetails.browser === 'firefox') {
    return updateMediaDevices({
      updatePermissions: true,
    });
  }

  if (webrtcAdapter.browserDetails.browser === 'chrome') {
    const mediaDevices = await updateMediaDevices({
      updatePermissions: true,
    });

    const micPermissions = getDevicePermissions(mediaDevices, 'audioinput');
    const camPermissions = getDevicePermissions(mediaDevices, 'videoinput');

    // Request user media on Chrome only on the first visit. It will remember our devices for future visits.
    if (camPermissions !== 'granted' && micPermissions !== 'granted') {
      return requestUserMedia();
    }

    return mediaDevices;
  }

  return requestUserMedia();
};
