import { fileClosed } from 'features/files/filesSlice';
import { selectGridPanel } from 'features/layout/features/content/contentSlice';
import { GridPanelName } from 'features/layout/features/content/types';
import { call, put, select } from 'redux-saga/effects';
import { board } from 'utils/whiteboard/BoardStateManager';

export function* onGridPanelClosedSaga() {
  const currentPanel: GridPanelName = yield select(selectGridPanel);

  if (currentPanel === 'files') {
    yield put(fileClosed());
  }

  // kill whiteboard. Important because this prevents breakouts from stupid state;
  // does not notify signaling, suppose that's ok. See if regression
  yield call(board.stop);
}
