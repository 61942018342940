import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { WhiteboardFullscreenControl } from 'features/whiteboard/WhiteboardFullscreenControl';
import React, { useMemo } from 'react';
import { GridPanelName } from 'features/layout/features/content/types';
import { WhiteboardCloseControl } from 'features/whiteboard/WhiteboardCloseControl';
import { FilePanelCloseControl } from 'features/content-library/components/file-panel/FilePanelCloseControl';

export const GridPanelControlsContainer = styled(Box)({
  position: 'absolute',
  top: '10px',
  zIndex: 999,
});

GridPanelControlsContainer.defaultProps = {
  className: 'grid-panel-controls-container',
};

const closeControlsMap: Record<GridPanelName, React.ComponentType> = {
  whiteboard: () => <WhiteboardCloseControl />,
  contentLibrary: () => <FilePanelCloseControl />,
  files: () => <FilePanelCloseControl />,
};

export type GridPanelControlsProps = {
  activePanel: GridPanelName;
  dragHandle?: boolean;
};

export const GridPanelControls = ({ dragHandle, activePanel }: GridPanelControlsProps) => {
  const GridPanelCloseControl = useMemo(() => closeControlsMap[activePanel], [activePanel]);

  return (
    <GridPanelControlsContainer
      className="hover-child"
      sx={{
        right: dragHandle ? '23px' : '10px',
      }}
    >
      <WhiteboardFullscreenControl />
      <GridPanelCloseControl />
    </GridPanelControlsContainer>
  );
};
