import { signalingFilesReceived } from 'features/files/actions';
import { onFilesReceivedSaga } from 'features/files/sagas/onFilesReceivedSaga';
import { takeEvery } from 'redux-saga/effects';

export default function* contentLibraryWatcher() {
  yield takeEvery(signalingFilesReceived, onFilesReceivedSaga);
  // yield takeEvery(signalingFileOpened, onSignalingFileOpenedSaga);
  // yield takeEvery(signalingFileClosed, onSignalingFileClosedSaga);
  // yield takeEvery(fileOpenRequested, onFileOpenedSaga);
  // yield takeEvery(fileClosed, closeFileSaga);
}
