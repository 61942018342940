import { streamMaximized } from 'features/layout/actions';
import { selectCurrentLayoutMode } from 'features/layout/features/config/configSlice';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { selectFilteredStreams } from 'features/layout/selectors';
import { LayoutMode, SourceDetails } from 'features/layout/types';
import { put, select } from 'redux-saga/effects';

export function* followActiveSpeakerSaga(activeSpeaker: SourceDetails) {
  const activeMode: LayoutMode = yield select(selectCurrentLayoutMode);

  // Maybe in future we'll want some other kind of follow speaker flag,
  // but for now this should be suffecient

  if (activeMode !== LayoutMode.auto) {
    return;
  }

  const maximizedStream: SourceDetails = yield select(selectMaximizedStream);
  const orderedStreams: SourceDetails[] = yield select(selectFilteredStreams);

  const screenshareStream = orderedStreams.find(
    (stream) => stream.kind === 'screenshare-remote' || stream.kind === 'screenshare-local'
  );

  // screenshare takes preference over everything else;
  if (maximizedStream?.kind === 'screenshare-remote' || screenshareStream) {
    return;
  }

  yield put(streamMaximized({ source: activeSpeaker }));
}
