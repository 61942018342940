import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { ApiError } from 'utils/http';

export const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
});

http.interceptors.response.use(
  (response) => {
    if (response.data) {
      response.data = camelcaseKeys(response.data, { deep: true });
    }

    return response;
  },
  (error) => {
    // @TODO use axios-retry for slow network

    if (error.response?.data) {
      return Promise.reject(new ApiError(error.response.data));
    }

    return Promise.reject(error);
  }
);
