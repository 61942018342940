import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { styleConstants } from 'theme/styleConstants';
import { canExposeInternals } from 'utils/development';

type PiPContextType = {
  pipWindowSupported: boolean;
  pipWindow: Window | null;
  requestPipWindow: (width: number, height: number) => Promise<void>;
  closePipWindow: () => void;
};

const PiPContext = createContext<PiPContextType | undefined>(undefined);

type PiPProviderProps = {
  children: React.ReactNode;
};

export function PiPProvider({ children }: PiPProviderProps) {
  // Detect if the feature is available.
  const pipWindowSupported = canExposeInternals() && 'documentPictureInPicture' in window;

  // Expose pipWindow that is currently active
  const [pipWindow, setPipWindow] = useState<Window | null>(null);

  // Close pipWidnow programmatically
  const closePipWindow = useCallback(() => {
    if (pipWindow != null) {
      pipWindow.close();
      setPipWindow(null);
    }
  }, [pipWindow]);

  // Open new pipWindow
  const requestPipWindow = useCallback(
    async (width: number, height: number) => {
      // We don't want to allow multiple requests.
      if (pipWindow != null) {
        return;
      }

      const pip = await window.documentPictureInPicture.requestWindow({
        width,
        height,
      });

      // Detect when window is closed by user
      pip.addEventListener('pagehide', () => {
        setPipWindow(null);
      });

      function copyStylesToPip(pw: Window) {
        [...document.styleSheets].forEach((styleSheet) => {
          try {
            const cssRules = [...styleSheet.cssRules].map((rule) => rule.cssText).join('');
            const style = document.createElement('style');

            style.textContent = cssRules;
            // @ts-ignore
            pw.document.head.appendChild(style);
          } catch (e) {
            const link = document.createElement('link');

            link.rel = 'stylesheet';
            link.type = styleSheet.type;
            // @ts-ignore
            link.media = styleSheet.media;
            // @ts-ignore
            link.href = styleSheet.href;
            // @ts-ignore
            pw.document.head.appendChild(link);
          }
        });

        const computedStyles = window.getComputedStyle(document.body);
        const bodyStyle = document.createElement('style');
        bodyStyle.textContent = `body { ${computedStyles.cssText} }`;
        pw.document.head.appendChild(bodyStyle);

        const htmlComputedStyles = window.getComputedStyle(document.documentElement);
        const htmlStyle = document.createElement('style');
        htmlStyle.textContent = `html { ${htmlComputedStyles.cssText} }`;
        pw.document.head.appendChild(htmlStyle);

        pw.document.documentElement.style.setProperty(
          '--toolbar-width',
          `${styleConstants.toolbar.width}px`
        );
      }

      const obs = new MutationObserver((mutationsList) => {
		console.log('MutationsList size is', mutationsList.length);
		
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList' || mutation.type === 'characterData') {
            copyStylesToPip(pip);
			break;
          }
        }
      });

      obs.observe(document.head, {
        childList: true,
        subtree: true,
        characterData: true,
      });

      copyStylesToPip(pip);

      setPipWindow(pip);
    },
    [pipWindow]
  );

  const value = useMemo(
    () => ({
      pipWindowSupported,
      pipWindow,
      requestPipWindow,
      closePipWindow,
    }),
    [closePipWindow, pipWindowSupported, pipWindow, requestPipWindow]
  );

  return <PiPContext.Provider value={value}>{children}</PiPContext.Provider>;
}

export function usePiPWindow(): PiPContextType {
  const context = useContext(PiPContext);

  if (context === undefined) {
    throw new Error('usePiPWindow must be used within a PiPContext');
  }

  return context;
}
