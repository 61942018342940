import React, { useCallback, useDeferredValue, useState } from 'react';

export const useSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const deferredQuery = useDeferredValue(searchQuery);

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  return {
    searchQuery: deferredQuery,
    onSearchInputChange: handleSearchChange,
  };
};
