import { takeEvery } from 'redux-saga/effects';
import { createLibraryFolderSaga } from 'features/content-library/sagas/createLibraryFolderSaga';
import {
  contentLibraryFileOpenRequested,
  contentLibraryFileUploadRequested,
  createContentFolderRequested,
  deleteContentLibraryFileRequested,
  deleteContentLibraryFolderRequested,
  renameContentFolderRequested,
  renameContentLibraryFileRequested,
  signalingContentLibraryFileClosed,
  signalingContentLibraryFileOpened,
} from 'features/content-library/actions';
import { renameLibraryFolderSaga } from 'features/content-library/sagas/renameLibraryFolderSaga';
import { renameContentLibraryFileSaga } from 'features/content-library/sagas/renameContentLibraryFileSaga';
import { deleteContentLibraryFileSaga } from 'features/content-library/sagas/deleteContentLibraryFileSaga';
import { deleteContentLibraryFolderSaga } from 'features/content-library/sagas/deleteContentLibraryFolderSaga';
import { uploadContentLibraryFilesSaga } from 'features/content-library/sagas/uploadContentLibraryFilesSaga';
import { fileClosed, fileUploadCancelled } from 'features/content-library/contentLibrarySlice';
import { cancelContentLibraryFileSaga } from 'features/content-library/sagas/cancelContentLibraryFileSaga';
import { openContentLibraryFileSaga } from 'features/content-library/sagas/openContentLibraryFileSaga';
import { onContentLibraryFileOpenedSaga } from 'features/content-library/sagas/onContentLibraryFileOpenedSaga';
import { onContentLibraryFileClosedSaga } from 'features/content-library/sagas/onContentLibraryFileClosedSaga';
import { closeContentLibraryFileSaga } from 'features/content-library/sagas/closeContentLibraryFileSaga';

export default function* contentLibraryWatcher() {
  // manage
  yield takeEvery(createContentFolderRequested, createLibraryFolderSaga);
  yield takeEvery(renameContentFolderRequested, renameLibraryFolderSaga);
  yield takeEvery(deleteContentLibraryFolderRequested, deleteContentLibraryFolderSaga);
  yield takeEvery(renameContentLibraryFileRequested, renameContentLibraryFileSaga);
  yield takeEvery(deleteContentLibraryFileRequested, deleteContentLibraryFileSaga);

  // upload
  yield takeEvery(contentLibraryFileUploadRequested, uploadContentLibraryFilesSaga);
  yield takeEvery(fileUploadCancelled, cancelContentLibraryFileSaga);

  // open file
  yield takeEvery(contentLibraryFileOpenRequested, openContentLibraryFileSaga);
  yield takeEvery(signalingContentLibraryFileOpened, onContentLibraryFileOpenedSaga);
  yield takeEvery(signalingContentLibraryFileClosed, onContentLibraryFileClosedSaga);
  yield takeEvery(fileClosed, closeContentLibraryFileSaga);
}
