import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { useRecorder } from 'features/recorder/useRecorder';
import { GridPanelCloseControl } from 'features/layout/GridPanel/GridPanelCloseControl';
import { useAppDispatch } from 'store/hooks';
import { fileClosed } from 'features/content-library/contentLibrarySlice';

export const FilePanelCloseControl = () => {
  const dispatch = useAppDispatch();
  const isRecorder = useRecorder();

  const { hasPermissions } = usePermissions();
  const canPresent = hasPermissions(PermissionTypes.presentContentLibrary);
  const isFeatureEnabled = useFeatureFlag('contentLibrary');

  if (!(isFeatureEnabled && canPresent) || isRecorder) {
    return null;
  }

  const handleClick = () => {
    dispatch(fileClosed());
  };

  return <GridPanelCloseControl icon="close" onClick={handleClick} />;
};
