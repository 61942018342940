import { call, put, select } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import {
  fileOpened,
  selectContentLibraryOpenedFile,
} from 'features/content-library/contentLibrarySlice';
import { contentLibraryFileOpenRequested } from 'features/content-library/actions';

// @TODO implement pagination
export function* openContentLibraryFileSaga(
  action: ReturnType<typeof contentLibraryFileOpenRequested>
) {
  const {
    id,
    // page
  } = action.payload;

  const openedFile: ReturnType<typeof selectContentLibraryOpenedFile> = yield select(
    selectContentLibraryOpenedFile
  );
  // const activePage: number = yield select(selectFilesActivePage);

  // if (id === openedFile?.id && page === activePage) {
  if (id === openedFile?.id) {
    return;
  }

  yield call(SignalingSocket.send, {
    event: 'openLibraryFile',
    data: {
      id,
      // page
    },
  });

  yield put(
    fileOpened({
      id,
      // page
    })
  );
}
