import { saveAs } from 'file-saver';

interface DownloadFileOptions {
  defaultFileName?: string;
  mimeType?: string;
}

export const downloadFile = (
  blob: Blob,
  contentDisposition: string | null,
  options: DownloadFileOptions = {}
): void => {
  const { defaultFileName = 'downloadedFile.txt', mimeType = 'text/plain' } = options;
  let fileName = defaultFileName;

  if (contentDisposition && contentDisposition.includes('filename=')) {
    fileName = contentDisposition.split('filename=')[1].split(';')[0].replace(/['"]/g, '');
  }

  const fileBlob = new Blob([blob], { type: mimeType });
  saveAs(fileBlob, fileName);
};
