import VideoPlaceholder from 'components/VideoPlaceholder';
import LocalVideo from 'components/LocalVideo';
import { selectVideoFacingMode } from 'features/user-media/userMediaSlice';
import { useAppSelector } from 'store/hooks';
import { selectLocalUser } from 'features/users/usersSlice';
import AspectRatio from 'components/AspectRatio';
import { Box, BoxProps } from '@mui/material';

type VideoRendererProps = {
  mediaStream: MediaStream | null;
  ratio?: number;
  className?: string;
  loading?: boolean;
};

const VideoRenderer = ({
  mediaStream,
  ratio,
  loading,
  sx = [],
  ...props
}: BoxProps<'div', VideoRendererProps>) => {
  const user = useAppSelector(selectLocalUser);
  const facingMode = useAppSelector(selectVideoFacingMode);

  return (
    <Box
      sx={[
        { bgcolor: user.avatarColor, overflow: 'hidden', borderRadius: '8px' },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      data-testid="video-renderer"
      {...props}
    >
      <AspectRatio ratio={ratio}>
        {mediaStream?.active ? (
          <LocalVideo srcObject={mediaStream} mirror={facingMode !== 'environment'} />
        ) : (
          <VideoPlaceholder loading={loading} />
        )}
      </AspectRatio>
    </Box>
  );
};
export default VideoRenderer;
