import RoomLoginForm from 'features/room/components/RoomLoginForm';
import React, { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { setConsentFlag } from 'utils/consentFlag';
import { eventBus } from 'utils/eventBus';
import * as Sentry from '@sentry/react';
import { handleAsyncFormValidation } from 'utils/handleAsyncFormValidation';
import { APIErrorMessage, getAPIDomain, lookupApiErrorMessage } from 'utils/http';
import { logger } from 'utils/logger';
import { isAPIError } from 'utils/types';
import {
  loginFailed,
  loginStarted,
  selectLoginError,
  selectRoomEntryProgress,
} from 'features/room/roomSlice';

export interface RoomLoginFormValues {
  login: string;
  consentGiven?: boolean;
}

const RoomLogin = () => {
  const { t } = useTranslation(['join', 'common']);
  const dispatch = useAppDispatch();

  const entryProgress = useAppSelector(selectRoomEntryProgress);

  const loginError = useAppSelector(selectLoginError);

  const {
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm<RoomLoginFormValues>({
    defaultValues: {
      login: eventBus.initState.username || '',
    },
    errors: loginError
      ? {
          login: { type: 'value', message: loginError },
        }
      : undefined,
  });

  const submitLogin: SubmitHandler<RoomLoginFormValues> = useCallback(
    async (data) => {
      dispatch(loginStarted());

      if (data.consentGiven) {
        logger.remote({ tier: 1 }).log('User accepted GDPR terms for team: ', getAPIDomain());

        setConsentFlag();
      }

      const resultAction = await dispatch(authorizeUser(data.login));

      if (authorizeUser.rejected.match(resultAction)) {
        dispatch(loginFailed());

        if (resultAction.payload) {
          handleAsyncFormValidation(resultAction.payload, setError);
        } else {
          const { error } = resultAction;
          Sentry.captureException(error);
          let message: APIErrorMessage | undefined;

          if (isAPIError(error)) {
            message = lookupApiErrorMessage(error);
          }

          setError('root.serverError', {
            type: 'server',
            message: message?.description || t('common:generic_error'),
          });
        }
      }
    },
    [dispatch, setError, t]
  );

  return (
    <RoomLoginForm
      onSubmit={handleSubmit(submitLogin)}
      errors={errors}
      control={control}
      isSubmitting={entryProgress === 'authorizing'}
    />
  );
};

export default RoomLogin;
