import { selectActiveMediaDevices, selectMediaDevices } from 'features/user-media/userMediaSlice';
import { AppStartListening } from 'store/listenerMiddleware';
import { eventBus } from 'utils/eventBus';

// @TODO FIXME try to avoid adding meta here. Consider
export const activeDeviceChangeListener = (startListening: AppStartListening) =>
  startListening({
    predicate: (action, currentState, originalState) => {
      const originalDevices = selectActiveMediaDevices(originalState);
      const newDevices = selectActiveMediaDevices(currentState);

      const audioinputChanged = originalDevices.audioinput !== newDevices.audioinput;
      const audiooutputChanged = originalDevices.audiooutput !== newDevices.audiooutput;
      const videoinputChanged = originalDevices.videoinput !== newDevices.videoinput;

      const mediaDeviceChanged = audiooutputChanged || audioinputChanged || videoinputChanged;

      if (mediaDeviceChanged) {
        action.meta = {
          audioinputChanged,
          audiooutputChanged,
          videoinputChanged,
        };
      }

      return mediaDeviceChanged;
    },
    effect: (action, { getState, getOriginalState }) => {
      const originalState = getOriginalState();
      const state = getState();
      const originalDevices = selectActiveMediaDevices(originalState);

      const newDevices = selectActiveMediaDevices(state);
      const allDevices = selectMediaDevices(state);

      (['audioinput', 'audiooutput', 'videoinput'] as MediaDeviceKind[]).forEach((kind) => {
        // @FIXME resolve type
        // @ts-ignore
        if (action.meta[`${kind}Changed`] as any) {
          const deviceId = newDevices[kind];
          const previousDeviceId = originalDevices[kind];

          const device = allDevices.find((row) => row.deviceId === deviceId && row.kind === kind);

          if (device) {
            eventBus.sendMessage(
              'internalMediaDeviceChanged',
              {},
              { kind, deviceId, previousDeviceId, label: device.label }
            );
          }
        }
      });
    },
  });
