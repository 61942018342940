import { createAction } from '@reduxjs/toolkit';
import {
  BroadcasterLimitReachedPayload,
  EntryAccessAwaitingPayload,
  PrepareRoomOptions,
  SessionEndedPayload,
  SessionEndsSoonPayload,
  RoomJoinedPayload,
} from 'features/room/types';

export const roomJoinRequest = createAction('room/joinRequest');

export const prepareRoomJoin = createAction<PrepareRoomOptions>('room/prepareRoomJoin');

export const startBroadcastRequest = createAction('room/startBroadcastRequest');

export const roomRendered = createAction('room/roomRendered');

export const endSession = createAction('room/endSessionRequest');
export const leaveSession = createAction('room/leaveSessionRequest');

export const signalingRoomJoined = createAction<RoomJoinedPayload>('signaling/roomJoined');
export const sessionEnded = createAction<SessionEndedPayload>('signaling/sessionEnded');
export const sessionLeft = createAction('signaling/sessionLeft');
export const participantsLimitReached = createAction('signaling/participantsLimitReached');

export const signalingSessionAwaitingStarted = createAction('signaling/sessionAwaitingStarted');

export const signalingEntryAccessAwaitingStarted = createAction<EntryAccessAwaitingPayload>(
  'signaling/entryAccessAwaitingStarted'
);

export const signalingRoomJoinPaused = createAction('signaling/roomJoinPaused');

export const languageChanged = createAction<string>('room/languageChanged');

export const broadcastSetupCancelled = createAction('room/broadcastSetupCancelled');

// room entry control
export const lockRoomRequested = createAction('room/lockRoomRequested');
export const unlockRoomRequested = createAction('room/unlockRoomRequested');
export const signalingRoomLocked = createAction('signaling/roomLocked');
export const signalingRoomUnlocked = createAction('signaling/roomUnlocked');

export const signalingBroadcasterLimitReached = createAction<BroadcasterLimitReachedPayload>(
  'signaling/broadcasterLimitReached'
);

export const signalingSessionEndsSoon = createAction<SessionEndsSoonPayload>(
  'signaling/sessionEndsSoon'
);

export const sessionEndsSoonDismissed = createAction('room/sessionEndsSoonDismissed');
